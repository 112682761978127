<template>
  <v-expansion-panels flat :value="opened[selected]">
    <v-expansion-panel
      v-for="(module, index) in selectedSection"
      :key="index"
      class="mb-3 border border-solid border-primary"
      active-class="panel-active"
    >
      <v-card flat class="panel-card ">
        <div class="py-1 d-flex align-center" :class="classes.header">
          <div
            class="d-flex flex-grow-1 clickable"
            @click="openSectionPanel(index)"
          >
            {{ moduleName(module) }}
          </div>
          <div v-if="!isStudent"
            class="d-flex align-center"
            :class="screenWidth < 600 ? 'justify-end' : 'justify-space-between'"
            :style="`width: ${sizes.actions}px`"
          >
            <v-btn
              :loading="editing"
              depressed
              small
              class="rounded-0 white black--text panel-action"
              @click="
                () => {
                  editModuleParams.id = module;
                  editModuleParams.outgoing = modules[module];
                  editModuleParams.show = true;
                }
              "
              >Edit
            </v-btn>
            <v-btn icon class="panel-action" @click="removeModule(module)">
              <v-progress-circular
                v-if="deleting"
                indeterminate
                color="orange"
                size="20"
              ></v-progress-circular>
              <img v-else :src="icons.delete" alt="delete-icon" />
            </v-btn>
            <v-btn icon @click="openSectionPanel(index)">
              <v-icon class="panel-arrow" size="25">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="!isStudent"
          class="py-1 panel-action-small align-center justify-end"
          :class="classes.header"
        >
          <v-btn
            :loading="editing"
            depressed
            small
            class="rounded-0 white black--text"
            @click="
              () => {
                editModuleParams.id = module;
                editModuleParams.outgoing = modules[module];
                editModuleParams.show = true;
              }
            "
            >Edit
          </v-btn>
          <v-btn icon>
            <v-progress-circular
              v-if="deleting"
              indeterminate
              color="orange"
              size="20"
            ></v-progress-circular>
            <img v-else :src="icons.delete" alt="delete-icon" />
          </v-btn>
        </div>
      </v-card>
      <!--      expansion-content class was added to target the inner default class and disable it's padding-->
      <v-expansion-panel-content class="pa-0 expansion-content">
        <module-display-component
          :module-id="module"
          :course-id="courseId"
        ></module-display-component>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-dialog :value="editModuleParams.show" width="unset">
      <create-module-panel
        :outgoing="editModuleParams.outgoing"
        :course-id="courseId"
        :loading="editing"
        :edit="true"
        @save="editModule"
        @update="v => (editModuleParams.outgoing = v)"
        @close="
          () => {
            editModuleParams.show = false;
            editModuleParams.outgoing = {};
            editModuleParams.id = undefined;
          }
        "
      ></create-module-panel>
    </v-dialog>
  </v-expansion-panels>
</template>
<script>
import CreateModulePanel from "#ecf/course-design/components/CreateModule/CreateModulePanel.vue";
import ModuleDisplayComponent from "#ecf/course-design/components/CourseOrganization/ModuleDisplayComponent.vue";
import { CourseDesignUtils } from "#ecf/course-design/mixins";

export default {
  props: ["selected", "courseId", "ownerId"],
  components: { ModuleDisplayComponent, CreateModulePanel },
  mixins: [CourseDesignUtils],
  data() {
    return {
      opened: {},
      editModuleParams: {
        outgoing: {},
        show: false,
        id: undefined
      },
      editing: false,
      deleting: false
    };
  },
  computed: {
    isStudent(){
      return this.$route.path.includes("students");
    },
    icons() {
      return {
        edit: require("#ecf/course-design/assessts/icon_section_edit.svg"),
        delete: require("#ecf/course-design/assessts/icon_delete.svg")
      };
    },
    modules() {
      if(this.isStudent){
        return this.$store.state.studentCourseDesignDashboard.courseModules[this.$ielts_course_id];
      }else{
        return this.$store.getters["design/modules"][this.courseId];
      }
    },
    sections() {
      if (this.isStudent) {
        return this.$store.state.studentCourseDesignDashboard.courseSections[this.$ielts_course_id];
      } else {
        return this.$store.getters["design/sections"][this.courseId];
      }
    },
    selectedSection() {
      if (this.selected === undefined) return [];
      return this.sections[this.selected].contents;
    },
    classes() {
      return {
        header: `px-${this.screenWidth < 600 ? 2 : 4}`,
        content: `px-${this.breakPointValues(
          6,
          6,
          6,
          6
        )} py-${this.breakPointValues(4, 4, 4, 4)}`
      };
    },
    sizes() {
      return {
        actions: this.screenWidth < 600 ? 50 : 150
      };
    }
  },
  watch: {
    selected(newVal) {
        if(newVal) {
          this.opened = {}
        }
    },
  },
  methods: {
    openSectionPanel(index) {
      if (!(this.selected in this.opened)) {
        this.$set(this.opened, this.selected, index);
      } else if (this.opened[this.selected] !== index) {
        this.$set(this.opened, this.selected, index);
      } else {
        this.$set(this.opened, this.selected, undefined);
      }
    },
    moduleName(moduleId) {
      return this.modules[moduleId].title;
    },
    async editModule() {
      try {
        this.editing = true;
        await this.$store.dispatch("design/editModule", {
          id: this.editModuleParams.id,
          title: this.editModuleParams.outgoing.title,
          contents: this.editModuleParams.outgoing.contents,
          courseId: this.courseId,
          ownerId: this.ownerId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.editing = false;
        this.$emit("update");
      }
    },
    async removeModule(moduleId) {
      try {
        this.deleting = true;
        await this.$store.dispatch("design/removeModule", {
          moduleId,
          courseId: this.courseId,
          ownerId: this.ownerId
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
        this.$emit("update");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-active .panel-card {
  border-bottom: 1px solid $primary;
  background-color: transparentize($primary, 0.87);
  color: $primary;
}

.panel-active .panel-arrow {
  transform: rotate(90deg);
  color: $primary;
}

.panel-action {
  pointer-events: none;
  visibility: hidden;

  @media only screen and (max-width: 599px) {
    pointer-events: none;
    display: none;
  }
}

.panel-active .panel-action {
  pointer-events: revert;
  visibility: visible;
}

.panel-action-small {
  display: none;
}

.panel-active .panel-action-small {
  @media only screen and (max-width: 599px) {
    display: flex;
  }
}

.expansion-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
